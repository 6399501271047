import React from "react";
import {useSpotList} from "../../../../../queries/spot/useSpotList";
import Box from "@mui/material/Box";
import {List, ListItem, ListItemButton} from "@mui/material";
import {useInfiniteSpotList} from "../../../../../queries/spot/useInfiniteSpotList";

import './style.css'
import {Invoices} from "../BillingPlanContainer";

const Billing = () => {
    const [currentSpot, setCurrentSpot] = React.useState<string | null>(null)

    const {data: spotData, fetchNextPage, hasNextPage} = useInfiniteSpotList()

    React.useEffect(() => {
        if (hasNextPage) {
            fetchNextPage()
        }
    }, [hasNextPage])


    return <div>
        <Box display={'flex'}>
            <Box pr={2} style={{
                height: '80vh',
                overflow: 'auto',
            }}>
                <List>
                    <ListItem className={currentSpot ? '' : 'active'}>
                        <ListItemButton onClick={() => setCurrentSpot(null)}>All</ListItemButton>
                    </ListItem>
                    {spotData?.pages.map((item) => (
                        item.data.map((spot) => (
                            <ListItem key={spot.id} className={currentSpot === String(spot.id) ? 'active' : ''}>
                                <ListItemButton onClick={() => setCurrentSpot(String(spot.id))}>{spot.company_name}</ListItemButton>
                            </ListItem>
                        )
                    )))}
                </List>
            </Box>
            <div style={{
                paddingLeft: 20,
            }}>
                <Invoices spot_id={currentSpot} />
            </div>
        </Box>
    </div>
}

export default Billing;