
export const app_urls = {
    business: {
        root: '/',
        signin: '/signin',
        signup: '/signup',
        forgot_password: '/forgot-password',
        reset_password: '/reset-password',
        dashboard: '/dashboard',
        spots: '/dashboard/spots',
        billings: '/dashboard/billings',
        reviews: (dashboard_id: string = ':dashboard_id') => `/dashboard/heroboard/reviews/${dashboard_id}`,
        print_templates: (dashboard_id: string = ':dashboard_id') => `/dashboard/heroboard/print-templates/${dashboard_id}`,
        settings: (dashboard_id: string = ':dashboard_id') => `/dashboard/heroboard/settings/${dashboard_id}`,
        billing: (dashboard_id: string = ':dashboard_id') => `/dashboard/heroboard/billing/${dashboard_id}`,
        email_signature: (dashboard_id: string = ':dashboard_id') => `/dashboard/heroboard/email-signature/${dashboard_id}`,
        onboarding: '/onboarding',
    },
    client: {
        root: '/',
        terms: '/terms',
        privacy: '/privacy',
        imprint: '/imprint',
        old: '/old',
        client_page: (slug = ':slug') => `/${slug}`,
        heroboard: (slug = ':slug') => `/${slug}/heroboard`,
        sign: '/sign',
        google_auth: '/auth/google'
    }
}