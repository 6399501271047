import {useQuery} from "@tanstack/react-query";
import queryKeys from "../queryKeys";
import {apiInstance} from "../../api/api";
import {InvoiceList} from "../../types/Invoice";

export const useInvoiceById = (invoice_id?: string | null, page: number = 1) => {
    return useQuery({
        queryKey: [queryKeys.current_invoice, invoice_id],
        queryFn: async () => {
            const params = {
                page,
                spot_id: invoice_id
            }

            if (!invoice_id) {
                delete params.spot_id;
            }
            const response = await apiInstance().get<InvoiceList>(`api/invoices`, {
                params
            });

            return response.data;
        },
    })
}