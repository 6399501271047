import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "service/AppContext";
import ModalEditBilling from "./ModalEditBilling";
import ModalEditPayment from "./ModalEditPayment";
import {ServiceFunctions} from "service/serviceFunctions";
import {useElements, useStripe} from "@stripe/react-stripe-js";
import {useTranslation} from "react-i18next";
import {getCompanySpot} from "../../../../api/companyApi";
import fileDownload from "js-file-download";
// @ts-ignore next line
import {ReactComponent as IconDownload} from "assets/dashboard/download.svg"
import {apiInstance} from "../../../../api/api";
import {useInvoiceById} from "../../../../queries/invoices/useInvoiceById";
import {useParams} from "react-router-dom";

const InvoiceRow = ({ item }: any) => {
  const date =
    item && item.created_at
      ? new Date(item.created_at).toLocaleDateString()
      : "";

  const invoice = require("assets/bill.png");  

  return (
    <div className="invoice-row">
      <div className="col-2">{date}</div>
      <div className="col-3">{item.company_name}</div>
      <div className="col-2">{item.amount_due || 0} €</div>
      <div className="col-2">
        <span
          className="invoice-status"
          style={
            item.status.toLowerCase() === "paid"
              ? {
                  width: "52px",
                  padding: "8px",
                  color: "#59C857",
                  backgroundColor: "rgba(89, 200, 87, 0.1)",
                  borderRadius: "10px",
                }
              : {
                  width: "52px",
                  padding: "8px 21px",
                  backgroundColor: "rgba(142, 142, 147, 0.1)",
                  borderRadius: "10px",
                }
          }
        >
          {item.status.toLowerCase() === "paid" ? "PAID" : "-"}
        </span>
      </div>
      <div className="col-2">
        <div className="d-flex">
          <a
            href={item.invoice_pdf || "#"}
            className="d-flex flex-column align-items-center"
            style={{
              textDecoration: "none",
              fontSize: "12px",
              color: "grey",            
            }}
          >
            <img
              src={invoice}
              alt=""
              className="mr-3"
              style={{ width: "26px", height: "30px" }}
            />
            <span>Invoice</span>
          </a>          
        </div>
      </div>
    </div>
  );
};

export const Invoices = ({spot_id}: {spot_id?: string | null}) => {
  const { t } = useTranslation();
  const {dashboard_id} = useParams()

  const company_id = dashboard_id || spot_id;
  const [page, setCurrent] = useState(1);
  const {data: invoices } = useInvoiceById(company_id, page)

  const pageHandler = (page: number) => {
    setCurrent(page);
  }

  const newArray =
      invoices && invoices.meta && invoices.meta.total && invoices.meta.per_page
          ? new Array(Math.ceil(invoices.meta.total / invoices.meta.per_page)).fill(
              0
          )
          : [];
  const pages =
      newArray && newArray.length
          ? newArray.map((item, index) => (item = index + 1))
          : [];

  const current =
      invoices && invoices.meta && invoices.meta.current_page
          ? invoices.meta.current_page
          : null;
  const lastPage =
      invoices && invoices.meta && invoices.meta.total && invoices.meta.per_page
          ? Math.ceil(invoices.meta.total / invoices.meta.per_page)
          : null;
  const firstPage =
      invoices && invoices.meta && invoices.meta.from ? invoices.meta.from : null;

  const [downloadingInvoices, setDownloadingInvoices] = useState(false);

  const backicon = require("../../../../assets/back.png");
  const fronticon = require("../../../../assets/front.png");

  const downloadInvoices = () => {
    setDownloadingInvoices(true);
    apiInstance().get('/api/invoices/download', {
      responseType: 'blob',
      params: {
        spot_id: company_id
      }
    }).then((response) => {
      const file_name =
        response.headers["content-disposition"]?.split("filename=")[1] ||
        'invoices.zip';

      fileDownload(response.data, file_name);
    }).finally(() => setDownloadingInvoices(false))
  }

  return (
    <div className="invoices-block">
      <div className={'d-flex justify-content-between align-items-center'}>
        <h5 className="fw-bold mb-4">{t('Invoices')}</h5>
        <button onClick={downloadInvoices} disabled={
          downloadingInvoices || !invoices || !invoices.data || !invoices.data.length
        } className={'btn btn-outline-success'}><IconDownload/> {t('Download invoices')}</button>
      </div>
      <div className="invoices-details">
        <div
          className="invoices-table"
          style={{overflowY: "auto", maxHeight: "50vh" }}
        >
          <div className="invoice-table-head">
            <div className="col-2">
              <span className="fw-bold invoice-col-title">{t('Date')}</span>
            </div>
            <div className="col-3">
              <span className="fw-bold invoice-col-title">{t('Created for')}</span>
            </div>
            <div className="col-2">
              <span className="fw-bold invoice-col-title">{t('Amount')}</span>
            </div>
            <div className="col-2">
              <span className="fw-bold invoice-col-title">{t('Status')}</span>
            </div>
            <div className="col-2">
              <span className="fw-bold invoice-col-title">{t('Download PDF')}</span>
            </div>
          </div>
          {invoices && invoices.data ? (
            invoices.data.map((item: any, index: any) => (
              <InvoiceRow item={item} key={index} />
            ))
          ) : (
            <div>
              <p className="mt-2 p-2" style={{ fontWeight: "600" }}>
                {t('No data found so far...')}
              </p>
            </div>
          )}
        </div>
      </div>
      <div
        className="pagination-block"
        style={{
          position: "absolute",
          bottom: "0",
          display: "flex",
          width: "100%",
        }}
      >
        <div className="d-flex align-items-center">
          {current && current !== 1 ? (
            <img
              src={backicon}
              alt=""
              className="me-3 pt-1"
              style={{ height: "18px", cursor: "pointer" }}
              onClick={() => pageHandler(current - 1)}
            />
          ) : null}
          {current && current !== 1 ? (
            <span className="page-link" onClick={() => pageHandler(1)}>
              {1}
            </span>
          ) : null}
          {current && current - 1 > 2 ? (
            <span
              className="page-link"
              style={{ cursor: "default", backgroundColor: "transparent" }}
            >
              ...
            </span>
          ) : null}
          {current && current > 2 ? (
            <span
              className="page-link"
              onClick={() => pageHandler(current - 1)}
            >
              {current - 1}
            </span>
          ) : null}
          {current ? (
            <span
              className="page-link page-item active"
              style={{ color: "white", backgroundColor: "black" }}
            >
              {current}
            </span>
          ) : null}
          {current && lastPage && lastPage - current > 1
            ? pages?.slice(current, current + 1).map((page, index) => (
                <span className="page-link" onClick={() => pageHandler(page)}>
                  {page}
                </span>
              ))
            : null}
          {current && lastPage && lastPage - current > 2 ? (
            <span
              className="page-link"
              style={{ cursor: "default", backgroundColor: "transparent" }}
            >
              ...
            </span>
          ) : null}
          {lastPage && current !== lastPage ? (
            <span className="page-link" onClick={() => pageHandler(lastPage)}>
              {lastPage}
            </span>
          ) : null}
          {current && current !== lastPage ? (
            <img
              src={fronticon}
              alt=""
              className="ms-3 pt"
              style={{ height: "18px", cursor: "pointer" }}
              onClick={() => pageHandler(current + 1)}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

const BillingPlan = () => {
  const { accessToken, userInfo: userData } = useContext<any>(AppContext);


  const { t } = useTranslation();

  const userEmail = React.useState(userData?.email || "");
  const userName = React.useState(userData?.name || "");
  const companyName = React.useState(userData?.company_name || "");
  const address = React.useState(userData?.spot_address || "");

  const [showBillingModal, setShowBillingModal] = useState(false);
  const [currentPlan, setCurrentPlan] = useState<{name:string}>()
  const [billingPortalUrl, setBillingPortalUrl] = useState()

  const handleCloseBilling = () => setShowBillingModal(false);
  const handleShowBilling = () => setShowBillingModal(true);

  const [showCardModal, setShowCardModal] = useState(false);

  const handleCloseCard = () => setShowCardModal(false);
  const handleShowCard = () => setShowCardModal(true);

  const stripe = useStripe();
  const elements = useElements();
  const [paymentMethod, setPaymentMethod] = useState<any>(null);

  useEffect(() => {
  if (accessToken) {
    ServiceFunctions.getPaymentMethod(accessToken.replaceAll('"', "")).then(
      (data) => {
        setPaymentMethod(data)

      }
    );
    }
  }, [accessToken]);

  useEffect(() => {
    if (!userData) return;

    getCompanySpot(userData?.spot_slug).then((data) => {
      ServiceFunctions.getTariff(data.data.tariff_id).then((data) => {
        setCurrentPlan(data.data)
      })
    })
    ServiceFunctions.getBillingPortal(window.location.href).then(({billing_portal_url}) => {
      setBillingPortalUrl(billing_portal_url)
    })
  }, [userData]);

  return (
    <div className="billing-plan-block">
      <h5 className="fw-bold">{t('Billing and Plan')}</h5>
      <div className="billing-details">
        <div style={{ borderBottom: "1px solid silver" }}>
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <div className="d-flex align-items-center justify-content-between">
                <p className="fw-bold m-0 p-0" style={{fontSize: "16px"}}>
                  {t('Plan details')}
                </p>
                {/* <a href="#" className="green-text fw-bold m-0 p-0" style={{ textDecoration: 'none' }}>Subscribe again</a> */}
              </div>
              <p className="grey-link">{currentPlan?.name}</p>
            </div>
            {
              billingPortalUrl && <a className="btn btn-sm btn-primary bg-dark" href={billingPortalUrl}>
                    Manage billing
                </a>
            }
          </div>
        </div>
        <div style={{borderBottom: "1px solid silver"}} className="mt-3">
          <div className="d-flex align-items-center justify-content-between mb-3">
            <p className="fw-bold m-0 p-0" style={{fontSize: "16px"}}>
              {t('Full Name')}
            </p>
            <span
              className="green-text fw-bold m-0 p-0"
              style={{ textDecoration: "none", cursor: "pointer" }}
              onClick={handleShowBilling}
            >
              {t('Edit')}
            </span>
          </div>
          <div className="d-flex justify-content-between mb-4">
            <p className="grey-link m-0">{t("Name")}</p>
            <p className="grey-link m-0 text-end" style={{ fontWeight: "600" }}>
              {userName ? userName : ""}
            </p>
          </div>
          <div className="d-flex justify-content-between mb-4">
            <p className="grey-link m-0">{t("Company Name")}</p>
            <p className="grey-link m-0 text-end" style={{ fontWeight: "600" }}>
              {companyName ? companyName : ""}
            </p>
          </div>
          <div className="d-flex justify-content-between mb-4">
            <p className="grey-link m-0">{t("Send Invoices to")}</p>
            <p className="grey-link m-0 text-end" style={{ fontWeight: "600" }}>
              {userEmail ? userEmail : ""}
            </p>
          </div>
          <div className="d-flex justify-content-between mb-4">
            <p className="grey-link m-0">{t("Address")}</p>
            <p className="grey-link m-0 text-end" style={{ fontWeight: "600" }}>
              {address ? address : ""}
            </p>
          </div>
        </div>
        {/*<div>*/}
        {/*  <div className="d-flex align-items-center justify-content-between mb-3 mt-2">*/}
        {/*    <p className="fw-bold m-0 p-0" style={{ fontSize: "16px" }}>*/}
        {/*      {t('Payment method')}*/}
        {/*    </p>*/}
        {/*    <span*/}
        {/*      className="green-text fw-bold m-0 p-0"*/}
        {/*      style={{ textDecoration: "none", cursor: "pointer" }}*/}
        {/*      onClick={handleShowCard}*/}
        {/*    >*/}
        {/*      {t('Edit')}*/}
        {/*    </span>*/}
        {/*  </div>*/}
        {/*  <div className="d-flex justify-content-between mb-4">*/}
        {/*    <p className="grey-link m-0">{t('Card number')}</p>*/}
        {/*    <p className="grey-link m-0 fw-bold text-end card-number">*/}
        {/*      {paymentMethod &&*/}
        {/*      paymentMethod.paymentMethod &&*/}
        {/*      paymentMethod.paymentMethod.card ? (*/}
        {/*        <span>*/}
        {/*          XXXX-XXXX-XXXX-{paymentMethod.paymentMethod.card.last4}*/}
        {/*        </span>*/}
        {/*      ) : (*/}
        {/*        <span></span>*/}
        {/*      )}*/}
        {/*    </p>*/}
        {/*  </div>*/}
        {/*  <div className="d-flex justify-content-between mb-4">*/}
        {/*    <p className="grey-link m-0">{t("Expiring on")}</p>*/}
        {/*    <p className="grey-link m-0 fw-bold text-end">*/}
        {/*      {paymentMethod &&*/}
        {/*      paymentMethod.paymentMethod &&*/}
        {/*      paymentMethod.paymentMethod.card ? (*/}
        {/*        <span>*/}
        {/*          {paymentMethod.paymentMethod.card.exp_month}/*/}
        {/*          {paymentMethod.paymentMethod.card.exp_year}*/}
        {/*        </span>*/}
        {/*      ) : (*/}
        {/*        <span></span>*/}
        {/*      )}*/}
        {/*    </p>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
      <ModalEditBilling
        show={showBillingModal}
        close={handleCloseBilling}
        open={handleShowBilling}
      />
      <ModalEditPayment
        show={showCardModal}
        paymentMethod={paymentMethod?.paymentMethod}
        close={handleCloseCard}
        open={handleShowCard}
      />
    </div>
  );
};

const BillingPlanContainer = () => {
  return (
    <div className="billing-plan-component">
      <Invoices />
      <BillingPlan />
    </div>
  );
};

export default BillingPlanContainer;
