import React from "react";
import {useParams} from "react-router-dom";
import {useSpotById} from "../../../../../queries/spot/useSpotById";

type TableProps = {
    img: string,
    description: React.JSX.Element,
    logo: string
}

const style = {
    borderRadius: '10px',
    border: '15px solid transparent',
    background: 'white',
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
    maxWidth: '740px',
}
const Table = ({img, description, logo}: TableProps) => {
    return <table id='table' style={style}>
        <tbody>
        <tr>
            <td>
                <img
                    src={img}
                    width="100%"
                    alt='Become a hero'/>
            </td>
        </tr>
        <tr>
            <td>
                <br/>
            </td>
        </tr>
        <tr>
            <td>
                <div style={{width: '100%'}}>
                    {description}
                </div>
            </td>
        </tr>
        <tr>
            <td>
                <img
                    src={logo} alt="Reviro"/>
            </td>
        </tr>
        </tbody>
    </table>
}

const TableSmall = ({img, description, logo}: TableProps) => {
    return <table id='table' style={style}>
        <tbody>
        <tr>
            <td width="50%" style={{borderRight: "1px solid #CEC", paddingRight: 10}}>
                {description}
                <img
                    src={logo} alt="Reviro"/>
            </td>
            <td>
                <img
                    src={img}
                    width="100%" alt='Become a hero'/>
            </td>
        </tr>
        </tbody>
    </table>
}

type SignatureTemplateProps = {
    lang: 'de' | 'en',
    small: boolean
}

const SignatureTemplate = ({lang, small}: SignatureTemplateProps) => {
    const image_de = require("assets/signature-img/image_de.jpg");
    const image_en = require("assets/signature-img/image_en.jpg");
    const image_de_small = require("assets/signature-img/image_de_small.jpg");
    const image_en_small = require("assets/signature-img/image_en_small.jpg");
    const logo = require("assets/signature-img/logo.jpg");
    const { dashboard_id } = useParams()
    const {data: currentSpot} = useSpotById(dashboard_id)

    const protocol = window.location.protocol;
    const host = window.location.host;
    const spot_url = `${protocol}//${host}/${currentSpot?.data.spot_slug}`

    const description_de = <p>
        Verbesser unseren Service durch dein <a href={spot_url}>Feedback</a> und wir pflanzen zu deinen Ehren einen Baum.
    </p>;
    const description_en = <p>Enhance our service with your <a href={spot_url}>Feedback</a> and we’ll plant a tree in your honour.</p>;

    const img = lang === 'de' ? small ? image_de_small : image_de : small ? image_en_small : image_en;
    const description = lang === 'de' ? description_de : description_en;

    return small ? <TableSmall img={img} description={description} logo={logo}/> : <Table img={img} description={description} logo={logo}/>
}

export default SignatureTemplate;
