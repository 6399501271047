import React, {useContext, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "app/hooks";
import {
    CompanySpotState,
    UpdateCompanySpotData,
    selectCompanySpotState,
    updateCompanySpot,
    fetchCompanySpot
} from "features/business/companySpotSlice";
import InputField from "components/uiKit/InputField";

import styles from './SettingsTab.module.scss';
import {AppContext} from "../../../../../service/AppContext";

type Data = {
  companyName: string,
  vatNumber: string,
    spot_slug: string,
}

const SettingsTab = () => {
  const { userInfo } = useContext<any>(AppContext);
  const companySpotState = useAppSelector(selectCompanySpotState) as CompanySpotState;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const companySpot  = companySpotState.data;

  useEffect(() => {
      if (userInfo?.spot_slug) {
          dispatch(fetchCompanySpot(userInfo?.spot_slug))
      }
  }, [userInfo?.spot_slug])

  const [active, setActive] = useState<boolean>(!!companySpot?.is_activated);
  const [data, setData] = useState<Data>({
      companyName: companySpot?.company_name || '',
      vatNumber: companySpot?.vat_number || '',
      spot_slug: companySpot?.spot_slug || '',
  })

  useEffect(() => {
      setActive(!!companySpot?.is_activated)
      setData({
          companyName: companySpot?.company_name || '',
          vatNumber: companySpot?.vat_number || '',
          spot_slug: companySpot?.spot_slug || '',
      })
  }, [companySpot]);


  const toggleRight = require("assets/toggle-right.png");
  const toggleLeft = require("assets/toggle-left.png");

  const onChange = (e: { target: { name: string, value: string } } ) => {
    const name = e.target.name
    const value = e.target.value

    setData(data => ({...data, [name]: value}))
  }

  const onSave = () => {
    if (companySpot?.company_id) {
      const updateData: UpdateCompanySpotData = {}

      if (active !== companySpot.is_activated) {
        updateData.is_activated = active
      }
      if (data.companyName && data.companyName !== companySpot.company_name) { 
        updateData.company_name = data.companyName
      }
      if (data.vatNumber && data.vatNumber !== companySpot.vat_number) { 
        updateData.vat_number = data.vatNumber
      }
      if (data.spot_slug && data.spot_slug !== companySpot.spot_slug) {
          updateData.slug = data.spot_slug
      }

      if (Object.keys(updateData).length === 0) {
        alert('No data was changed.');
        return;
      }
     
      if (companySpot?.spot_slug) {
        dispatch(updateCompanySpot(updateData, companySpot.spot_slug))
      }
    }
  }  

  return companySpot ? (
      <div className={`setting-tab col-md-10 col-lg-8 col-12 col-xl-6 container ${styles.settingsTab}`}>
          {active ? (
              <div className="setting-toggle">
                  <h3 className="fs-4 text-center" style={{fontWeight: "600"}}>
                      {t("Your Heroboard is Activated")}
                  </h3>
                  <div
                      className="mt-3 w-100"
                      style={{
                          backgroundColor: "rgba(89, 200, 87, 0.1)",
                          borderRadius: "24px",
                          display: "flex",
                          alignItems: "center",
                          padding: "10px 30px",
                      }}
                  >
                      <img
                          src={toggleRight}
                          alt=""
                          style={{width: "64px", marginRight: "10px", cursor: "pointer"}}
                          onClick={() => setActive(false)}
                      />
                      <h1
                          className="pt-1"
                          style={{fontSize: "40px", color: "rgba(89, 200, 87, 1)"}}
                      >
                          {t("ACTIVATED")}
                      </h1>
                  </div>
                  <p className="mt-3 text-center">
                      {t("This means that users can leave feedback about your business")}
                  </p>
              </div>
          ) : (
              <div className="setting-toggle">
                  <h3 className="fs-4 text-center" style={{fontWeight: "600"}}>
                      {t("Your Heroboard is Deactivated")}
                  </h3>
                  <div
                      className="mt-3 w-100"
                      style={{
                          backgroundColor: "rgba(254, 238, 241, 1)",
                          borderRadius: "24px",
                          display: "flex",
                          alignItems: "center",
                          padding: "10px 30px",
                      }}
                  >
                      <img
                          src={toggleLeft}
                          alt=""
                          style={{width: "64px", marginRight: "10px", cursor: "pointer"}}
                          onClick={() => setActive(true)}
                      />
                      <h1
                          className="pt-1"
                          style={{fontSize: "40px", color: "rgba(242, 86, 116, 1)"}}
                      >
                          {t("DEACTIVATED")}
                      </h1>
                  </div>
                  <p className="mt-3 text-center">
                      {t("This means that users can NOT leave feedback about your business")}
                  </p>
              </div>
          )}
          <div className="mt-2">
              <p className="fw-bold m-0 p-0" style={{fontSize: "16px"}}>
                  {t("Business Name")}
              </p>
              <p className="grey-link mt-1">
                  {t("Change the Business Name that is Displayed")}
              </p>
          </div>
          <div>
              <InputField
                  defaultValue={companySpot.company_name}
                  name="companyName"
                  value={data.companyName}
                  onChange={onChange}
                  required
              />
          </div>
          <div className="mt-3">
              <p className="fw-bold m-0 p-0" style={{fontSize: "16px"}}>
                  {t("Link to your Heroboard")}
              </p>
              {/*<p className="grey-link mt-1">*/}
              {/*    {t("Change the link to your Heroboard")}*/}
              {/*</p>*/}
          </div>
          <div>
              <InputField
                  defaultValue={companySpot.spot_slug || ''}
                  name="spot_slug"
                  value={data.spot_slug}
                  onChange={() => {}}
                  required
                  disabled
              />
          </div>
          <div className="mt-3">
              <p className="fw-bold m-0 p-0" style={{fontSize: "16px"}}>
                  {t("NGO")}
              </p>
              <p className="grey-link mt-1">
                  {t("Change the NGO you support")}
              </p>
          </div>
          <div>
              <InputField
                  defaultValue={companySpot.ngo || t("One Tree Planted")}
                  name="ngo"
                  required
                  disabled
              />
          </div>
          <div className="mt-3">
              <p className="fw-bold m-0 p-0" style={{fontSize: "16px"}}>
                  {t("Project")}
              </p>
              <p className="grey-link mt-1">
                  {t("Change the Project you support")}
              </p>
          </div>
          <div>
              <InputField
                  defaultValue={companySpot.project || t("Tree Planting")}
                  name="project"
                  required
                  disabled
              />
          </div>
          {/* <div className="mt-3">
        <p className="fw-bold m-0 p-0" style={{ fontSize: "16px" }}>
          {t("VAT Number")}
        </p>
        <p className="grey-link mt-1">
          {t("Change the VAT Number")}
        </p>
      </div>
      <div>
        <InputField 
          defaultValue={companySpot.vat_number}
          name="vatNumber"
          value={data.vatNumber}
          onChange={onChange}
          required    
        />
      </div> */}
          <div className="mt-3">
              <p className="fw-bold m-0 p-0" style={{fontSize: "16px"}}>
                  {t("Review Platform")}
              </p>
              <p className="grey-link mt-1">
                  {t("Change your review platform")}
              </p>
          </div>
          <div>
              <InputField
                  defaultValue={companySpot.review_platform || t("Google")}
                  name="reviewPlatform"
                  required
                  disabled
              />
          </div>
          <div className="text-center mt-4">
              <button
                  className="submit-review active-btn-green"
                  style={{padding: "10px 20px"}}
                  onClick={onSave}
                  disabled={companySpotState.isLoading}
              >
                  {companySpotState.isLoading ? 'Loading.....' : t("Save")}
              </button>
          </div>
      </div>
  ) : null;
};

export default SettingsTab;
