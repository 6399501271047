import React from "react";
import {app_urls} from "../routes/urls";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {useParams} from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const useBusinessNav = () => {
  const { t } = useTranslation();
  const { dashboard_id } = useParams()
  const navstar = require("assets/navstar.png");
  const invoices = require("assets/invoices.png");
  const printer = require("assets/template.png");
  const setting = require("assets/setting.png");

  const dashboard_urls = [
    {
      name: "Heroboards",
      url: app_urls.business.spots,
      icon: null,
      componentIcon: <ArrowBackIosIcon className={'nav-tab-icon'}/>
    },
    {
      name: t("Reviews"),
      url: app_urls.business.reviews(dashboard_id),
      icon: navstar,
      componentIcon: null
    },
    {
      name: t("Templates"),
      url: app_urls.business.print_templates(dashboard_id),
      icon: printer,
      componentIcon: null
    },
    {
      name: t("Email signature"),
      url: app_urls.business.email_signature(dashboard_id),
      icon: setting,
      componentIcon: null
    },
    {
      name: t("Settings"),
      url: app_urls.business.settings(dashboard_id),
      icon: setting,
      componentIcon: null
    },
    {
      name: t("Billing & Plan"),
      url: app_urls.business.billing(dashboard_id),
      icon: invoices,
      componentIcon: null
    }
  ]

  const board_urls = [
    {
      name: "Heroboards",
      url: app_urls.business.spots,
      icon: navstar,
      componentIcon: null
    },
    {
      name: "Billing",
      url: app_urls.business.billings,
      icon: invoices,
      componentIcon: null
    }
  ]

  const sideBarUrl = document.location.href.includes('heroboard') ? dashboard_urls : board_urls

  const current = [...sideBarUrl].find(item => document.location.href.includes(item.url))

  return { sideBarUrl, current }
}

export default useBusinessNav;