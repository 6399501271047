import axios from "axios";
import getCookie from "../utils/getCookie";

const isDevelopment = process.env.NODE_ENV === "development";
export const apiUrl = isDevelopment
  ? "https://api.reviro.tech.anroit.com"
  : `https://api.${window.location.hostname}`;

export const apiInstance = () => {
  const defaultOptions = {
    baseURL: apiUrl,
    headers: {
      "Content-Type": "application/json",
      'locale': localStorage.getItem('language')
    },
  };

  const instance = axios.create(defaultOptions);

  instance.interceptors.request.use((config) => {
    let token: any = getCookie("accessToken");
    token = token?.replace(/['"]/g, "");
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      // const navigate = useNavigate();
      if (error.response.status === 401) {
        // console.log("unauthorized, logging out ...");
        // window.location.href = "/business/signin";
      }
      return Promise.reject(error);
    }
  );

  return instance;
};
