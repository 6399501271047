import React, { useState } from "react";
// import Pagination from "react-bootstrap/Pagination";
import ReviewModal from './ReviewModal'
import ReviewModalContent from './ReviewModalContent'
import { useTranslation } from "react-i18next";
import { useParams} from "react-router-dom";

import styles from './ReviewTable.module.scss'
import {Tooltip} from "@mui/material";
import {useReviewsBySpot} from "../../../../../queries/reviews/useReviewsBySpot";

export type ActiveReview = {
    created_at: Date,
    user_name: string,
    review_text: string,
    rating: number,
} | null

const TableRow = ({ review, onTableReviewClick }: any) => {
  const staricon = require("assets/gold-star.png");
  const pdficon = require("assets/pdf.png");
  const invoice = require("assets/bill.png");

  const rating = new Array(review.rating).fill(0);

  return (
    <div className="review-row pt-2" onClick={() => onTableReviewClick(review)}>
      <div className="col pe-3">
        <span>
          {new Date(review.created_at).toLocaleDateString()}
        </span>
      </div>
      <div className="col pe-3">
        <span>{review.platform}</span>
      </div>
      <div className={`col pe-3 ${styles.overflowEllipsis}`}>
        <span>{review.company_name}</span>
      </div>
      <div className={`col pe-3 ${styles.overflowEllipsis}`}>
        <span>{review.user_name}</span>
      </div>
      <div className={`col pe-3 ${styles.overflowEllipsis}`} onClick={event => event.stopPropagation()}>
        <Tooltip title={review.user_email}>
          <span>{review.user_email}</span>
        </Tooltip>
      </div>
      <div className="col pe-3">
        <span>
          {rating &&
            rating.map((item, index) => (
              <img
                src={staricon}
                key={index}
                alt=""
                style={{ maxWidth: "12px" }}
              />
            ))}
        </span>
      </div>
      <div className={`col pe-3 ${styles.overflowEllipsis}`}>
        <span>{review.review_text}</span>
      </div>
      <div className="col pe-3" onClick={e => e.stopPropagation()}>
        <div className="d-flex">
          {review.invoice && (
          <a
              href={review.invoice || "#"}
              className="d-flex flex-column align-items-center"
              style={{
                textDecoration: "none",
                fontSize: "12px",
                color: "grey",
              }}
          >
            <img
                src={invoice}
                alt=""
                className="mr-3"
                style={{width: "26px", height: "30px"}}
            />
            <span>Invoice</span>
          </a>
              )}
        </div>
      </div>
      <div className="col pe-3" onClick={e => e.stopPropagation()}>
        <span>
          <a href={review.certificate_download_link || '#'} download>
            <img src={pdficon} alt="" style={{maxHeight: "3.25vh", cursor: 'alias'}}/>
          </a>
        </span>
      </div>
      <div className="col pe-3">
        <span>{review.ngo || 'One Tree Planted'}</span>
      </div>
      <div className="col pe-3">
        <span>{review.project || 'Trees'}</span>
      </div>
    </div>
  );
};

const ReviewTable = () => {
  const {dashboard_id} = useParams()

  const [activePage, setActivePage] = useState<number>(1);
  const {data: reviewsData} = useReviewsBySpot(dashboard_id, activePage)

  const reviews = reviewsData?.data
  const { t } = useTranslation();

    console.log(activePage);

    const newArray =
    reviews && reviews.total && reviews.per_page
      ? new Array(Math.ceil(reviews.total / reviews.per_page)).fill(0)
      : [];
  const pages =
    newArray && newArray.length
      ? newArray.map((item, index) => (item = index + 1))
      : [];

  const current =
    reviews && reviews.current_page ? reviews.current_page : 0;
  const lastPage =
    reviews && reviews.total && reviews.per_page
      ? Math.ceil(reviews.total / reviews.per_page)
      : null;
  // const firstPage = reviews && reviews.from ? reviews.from : null;

  const [activeReview, setActiveReview] = useState<ActiveReview>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);


  const backicon = require("../../../../../assets/back.png");
  const fronticon = require("../../../../../assets/front.png");

  const onTableReviewClick = (review: ActiveReview) => {
    setActiveReview(review)
    setIsModalOpen(true)
  }

  const onModalClose = () => {
    setIsModalOpen(false)
    setTimeout(() => {
      setActiveReview(null)
    }, 300)
  }

  return (
    <div
      className="business-review-table"
      style={{ minHeight: "80vh", position: "relative" }}
    >
      <div
        className="table-content"
        style={{ height: "70vh", overflowY: 'auto' }}
      >
        <div className="table-head">
          <div className="col pe-3">
            <span className="fw-bold">{t("Date")}</span>
          </div>
          <div className="col pe-3">
            <span className="fw-bold">{t("Platform")}</span>
          </div>
          <div className="col pe-3">
            <span className="fw-bold">{t("Business Profile")}</span>
          </div>
          <div className="col pe-3">
            <span className="fw-bold">{t("Name")}</span>
          </div>
          <div className="col pe-3">
            <span className="fw-bold">E-mail</span>
          </div>
          <div className="col pe-3">
            <span className="fw-bold">{t("Stars")}</span>
          </div>
          <div className="col pe-3">
            <span className="fw-bold">{t("Review Text")}</span>
          </div>
          <div className="col pe-3">
            <span className="fw-bold">{t("Invoice")}</span>
          </div>
          <div className="col pe-3">
            <span className="fw-bold">{t("Certificate")}</span>
          </div>
          <div className="col pe-3">
            <span className="fw-bold">{t("NGO")}</span>
          </div>
          <div className="col pe-3">
            <span className="fw-bold">{t("Project")}</span>
          </div>
        </div>
        {reviews &&
          reviews.reviews.map((object: any, index: any) => (
            <TableRow key={index} review={object} onTableReviewClick={onTableReviewClick} />
          ))}
      </div>
      {reviews?.reviews?.length && reviews?.total > reviews?.per_page ? (
        <div
            className="pagination-block"
            style={{
                position: "absolute",
                bottom: "0",
                display: "flex",
                justifyContent: "center",
                width: "100%",
            }}
        >
            <div className="d-flex align-items-center">
                {current && current !== 1 ? (
                    <img
                    src={backicon}
                    alt=""
                    className="me-3 pt-1"
                    style={{ height: "18px", cursor: "pointer" }}
                    onClick={() => setActivePage(current - 1)}
                    />
                ) : null}
                {pages && pages.length > 0 && current !== 1 ? (
                    <span className={styles.pageLink} onClick={() => setActivePage(1)}>
                    {1}
                    </span>
                ) : null}
                {current - 1 > 2 ? (
                    <span
                    className={styles.pageLink}
                    style={{ cursor: "default", backgroundColor: "transparent" }}
                    >
                    ...
                    </span>
                ) : null}
                {current > 2 ? (
                    <span
                    className={styles.pageLink}
                    onClick={() => setActivePage(current - 1)}
                    >
                    {current - 1}
                    </span>
                ) : null}
                {current ? (
                    <span className={`${styles.pageLink} ${styles.pageLinkActive}`}>
                    {current}
                    </span>
                ) : null}
                {lastPage && lastPage - current > 1
                    ? pages?.slice(current, current + 1).map((page) => (
                        <span className={styles.pageLink} onClick={() => setActivePage(page)}>
                        {page}
                        </span>
                    ))
                    : null}
                {lastPage && lastPage - current > 2 ? (
                    <span
                    className={styles.pageLink}
                    style={{ cursor: "default", backgroundColor: "transparent" }}
                    >
                    ...
                    </span>
                ) : null}
                {lastPage && current !== lastPage ? (
                    <span className={styles.pageLink} onClick={() => setActivePage(lastPage)}>
                    {lastPage}
                    </span>
                ) : null}
                {current !== lastPage && lastPage ? (
                    <img
                    src={fronticon}
                    alt=""
                    className="ms-3 pt"
                    style={{ height: "18px", cursor: "pointer" }}
                    onClick={() => setActivePage(current + 1)}
                    />
                ) : null}
            </div>        
        </div>
      ): null}
      <ReviewModal open={isModalOpen} handleClose={onModalClose}>
         <ReviewModalContent review={activeReview} /> 
      </ReviewModal>
    </div>
  );
};

export default ReviewTable;
