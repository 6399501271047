import React from "react";
import {useSpotList} from "../../../../../queries/spot/useSpotList";
import {Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import Box from "@mui/material/Box";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import {useNavigate} from "react-router-dom";
import {app_urls} from "../../../../../routes/urls";

export default function () {
    const [page, setPage] = React.useState(1)
    const navigate = useNavigate()
    const {data: spots} = useSpotList(page)

    return <div>
        <div style={{
            minHeight: "600px",
        }}>
            <TableContainer>
                <Table style={{
                    width: "100%",
                    verticalAlign: "top",
                    borderSpacing: '10px'
                }}
                >
                    <TableHead>
                    <TableRow>
                        <TableCell>Company Name</TableCell>
                        <TableCell>Address</TableCell>
                        <TableCell>Average Rating</TableCell>
                        <TableCell>Total Reviews</TableCell>
                        <TableCell>Active</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {spots?.data.map(spot => <TableRow key={spot.id} style={{
                            cursor: "pointer"
                        }} onClick={() => navigate('/business' + app_urls.business.reviews(String(spot.spot_id)))}>
                            <TableCell>{spot.company_name}</TableCell>
                            <TableCell>{spot.address}</TableCell>
                            {
                                spot.reviews_avg_rating === undefined ?
                                    <TableCell>N/A</TableCell> :
                                    <TableCell>{spot.reviews_avg_rating} / 5</TableCell>
                            }
                            <TableCell>{spot.reviews_count}</TableCell>
                            <TableCell><span style={
                                {
                                    color: spot.is_activated ? "#59c857" : "red",
                                    fontWeight: "bold"
                                }
                            }>
                                {spot.is_activated ? <CheckBoxIcon/> : <DisabledByDefaultIcon/>}
                            </span></TableCell>
                        </TableRow>)}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
        {/*pagination mui*/}
        <Box display={'flex'} justifyContent={'center'} pt={3}>
            <Pagination count={spots?.meta.last_page || 1} color="primary" onChange={
                (event, value) => {
                    setPage(value)
                }
            }/>
        </Box>
    </div>;
}